<template>
  <div>
    <b-tabs>
      <b-tab title="应收">
        <receivable-list></receivable-list>
      </b-tab>

      <b-tab title="应付" >
        <payable-list></payable-list>
      </b-tab>

      <b-tab title="毛利" >
        <profit-list></profit-list>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTabs, BTab,
} from 'bootstrap-vue'
import payableList from "@/views/apps/logisticsreport/payable/PayableList";
import receivableList from "@/views/apps/logisticsreport/receivable/receivableList";
import profitList from "@/views/apps/logisticsreport/profit/profitList";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
    payableList,
    receivableList,
    profitList,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
