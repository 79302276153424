<template>
  <div>
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="exportExcel"
                  class="mr-1"
              >
                <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                />
                <span class="align-middle">导出</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- 高级搜索 -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition">
            <b-row>
              <!--承运人-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="承运人"
                    label-for="carriager"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="carriager"
                      v-model="condition.carriager"
                      :options=carriager_options
                      class="select-size-sm"
                      placeholder="请选择承运人"
                      :reduce="option => option.label"
                  />
                </b-form-group>
              </b-col>
              <!--车辆类型-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="车辆类型"
                    label-for="car_type"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="car_type"
                      :options="getCodeOptions('warehouse_feed_cartype')"
                      v-model="condition.car_type"
                      class="select-size-sm"
                      placeholder="请选择车辆类型"
                      :reduce="option => option.label"
                  />
                </b-form-group>
              </b-col>
              <!--费用类型-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="费用类型"
                    label-for="fee_type"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="fee_type"
                      v-model="condition.fee_type"
                      :options="fee_type_options"
                      class="select-size-sm"
                      placeholder="请选择费用类型"
                      :reduce="option => option.label"
                  />
                </b-form-group>
              </b-col>
              <!--费用日期-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="费用日期"
                    label-size="sm"
                >
                  <flat-pickr
                      v-model="condition.date"
                      class="form-control"
                      :config="rangeConfig"
                  />
                </b-form-group>
              </b-col>
              <!--查询、重置按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>


      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <template v-slot:custom-foot="data">
          <b-tr>
            <b-td :colspan="1" variant="primary">
              <span><strong>合计</strong></span>
            </b-td>
            <b-td :colspan="5" variant="primary">
              <span><strong></strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{totalAmount}}</strong></span>
            </b-td>
            <b-td :colspan="3" variant="primary">
              <span><strong></strong></span>
            </b-td>
          </b-tr>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>
</template>


<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import payableUseList from './payableUseList'
import logisticsreportStore from '../logisticsreportStore'
import Ripple from "vue-ripple-directive";
import axios from '@axios'
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      isShowCard: false,
      carriager_options: [],
      customer_name_options:[],
      fee_type_options:[{label:'物流订单'},{label:'仓储装卸'}],
      rangeConfig: {
        mode: 'range',
      },
    }
  },
  directives: {
    Ripple,
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('logisticsreport')) store.registerModule('logisticsreport', logisticsreportStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('logisticsreport')) store.unregisterModule('logisticsreport')
    })


    const advanced_search = function () {
      this.isShowCard = !this.isShowCard
    }

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition = {}
      refetchData()
    }

    const initOptions = function () {
      let that = this
      axios.post('/api/logisticsuser/list').then(function (res) {
        let list = res.data.data.list
        list.forEach(item => {
          if (item.user_type === 2) {
            that.carriager_options.push({label: item.user_name})
          }
        })
      })
    }

    const exportExcel = function () {
      const params = {
        carriager:condition.value.carriager,
        car_type:condition.value.car_type,
        date:condition.value.date,
        fee_type:condition.value.fee_type,
      }
      store.dispatch('logisticsreport/logisticsPayableExcel', params).then(res => {
        toast.success('导出成功')
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = '应付费用报表' + new Date().getTime()
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data)
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })

    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      totalAmount,

      // UI
    } = payableUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      advanced_search,
      searchByCondition,
      resetCondition,
      condition,
      totalAmount,
      initOptions,
      exportExcel,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
  created() {
    this.initOptions()
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
