import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        payableSearch(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/logisticsreport/payableSearch', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        receivableSearch(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/logisticsreport/receivableSearch', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        profitSearch(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/logisticsreport/profitSearch', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        logisticsPayableExcel(ctx,params) {
            return new Promise((resolve, reject) => {
                axios({
                    url: '/api/exportexcelfile/logisticsPayableExcel',
                    method: 'get',
                    params: params,
                    responseType: 'blob',
                }).
                then(response => resolve(response)).
                catch(error => reject(error))
            })
        },
        logisticsReceivableExcel(ctx,params) {
            return new Promise((resolve, reject) => {
                axios({
                    url: '/api/exportexcelfile/logisticsReceivableExcel',
                    method: 'get',
                    params: params,
                    responseType: 'blob',
                }).
                then(response => resolve(response)).
                catch(error => reject(error))
            })
        },
        logisticsProfitExcel(ctx,params) {
            return new Promise((resolve, reject) => {
                axios({
                    url: '/api/exportexcelfile/logisticsProfitExcel',
                    method: 'get',
                    params: params,
                    responseType: 'blob',
                }).
                then(response => resolve(response)).
                catch(error => reject(error))
            })
        }
    },
}
