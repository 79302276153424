import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function profitUseList() {
    // Use toast
    const toast = useToast()
    const condition = ref({})
    const refListTable = ref(null)
    const totalReceive = ref(0)
    const totalPayable = ref(0)
    const totalProfit = ref(0)
    const totalProfitPercent = ref(0)

    // Table Handlers
    const tableColumns = [
        { key: 'no', label: '单据编号', sortable: true },
        { key: 'customer_name', label: '客户名称', sortable: true },
        { key: 'product_name', label: '货物名称', sortable: true },
        { key: 'carriager', label: '承运人', sortable: true },
        { key: 'car_type', label: '车辆类型', sortable: true },
        { key: 'car_no', label: '车牌号', sortable: true },
        { key: 'car_cycle', label: '车型', sortable: true },
        { key: 'fee_type', label: '费用类型', sortable: true },
        { key: 'date', label: '费用日期', sortable: true },
        { key: 'receive_fee', label: '应收费用', sortable: true },
        { key: 'payable', label: '应付费用', sortable: true },
        { key: 'profit', label: '毛利', sortable: true },
        { key: 'profit_percent', label: '毛利率', sortable: true },
    ]

    const start = ref(1)
    const limit = ref(10)
    const limitOptions = [10, 25, 50, 100]
    const listTotals = ref(0)
    const searchKey = ref('')
    const orderBy = ref('no')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
            to: limit.value * (start.value - 1) + localItemsCount,
            of: listTotals.value,
        }
    })

    const refetchData = () => {
        refListTable.value.refresh()
    }

    watch([start, limit, searchKey], () => {
        refetchData()
    })

    const searchList = (ctx, callback) => {
        store
            .dispatch('logisticsreport/profitSearch', {
                carriager:condition.value.carriager,
                car_type:condition.value.car_type,
                customer_name:condition.value.customer_name,
                fee_type:condition.value.fee_type,
                date:condition.value.date,
                search_key: searchKey.value,
                start: start.value,
                limit: limit.value,
                order_by: orderBy.value,
                order_desc: isSortDirDesc.value === true ? 'desc':'',
            })
            .then(response => {
                const data = response.data.data
                const list = data.ext.list
                listTotals.value = data.ext.totals
                totalReceive.value = data.ext.totalReceive
                totalPayable.value = data.ext.totalPayable
                totalProfit.value = data.ext.totalProfit
                totalProfitPercent.value = data.ext.totalProfitPercent
                callback(list)
            })
            .catch((e) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: '列表获取错误',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals: listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,
        condition,
        refetchData,
        totalReceive,
        totalPayable,
        totalProfit,
        totalProfitPercent,

        // Extra Filters
    }
}
